import React, { useEffect, useRef } from 'react';
import { Container } from '../../../components';
import logoAbrace from '../../../assets/imgs/logo-abrace.svg';
import { funcParallax } from '../../../utils';

export default function CampanhaSection() {

    const idElem: any = useRef(null);

    useEffect(() => {
        setTimeout(() => {
            funcParallax(idElem, {
                start: "top center",
            })
        }, 1000);
    }, []);

    return (
        <section id="campanha" ref={idElem} className="bg-gray-2 relative flex flex-col sm:flex-row">
            <div className="relative overflow-hidden sm:absolute top-0 left-0 sm:w-1/2 min-h-1/2 sm:h-full">
                <img className="relative object-cover w-full h-full" src={require('../../../assets/imgs/campanha/bg.jpg').default} alt="" />
                <img data-depth={0.3} className="parallax absolute bottom-0 left-0 ml-24 w-5/6 h-auto" src={require('../../../assets/imgs/campanha/2.png').default} alt="" />
                <img data-depth={0.8} className="parallax absolute bottom-0 left-0 object-cover w-full h-full" src={require('../../../assets/imgs/campanha/1.png').default} alt="" />
                <img data-depth={1.3} className="parallax absolute bottom-0 left-0 object-cover w-full h-full" src={require('../../../assets/imgs/campanha/3.png').default} alt="" />
                <img className="absolute bottom-0 right-0" src={require('../../../assets/imgs/campanha/4.png').default} alt="" />
            </div>
            <Container className="flex flex-wrap flex-1 sm:justify-end items-center text-center sm:text-left">
                <div className="w-full sm:w-1/2 sm:px-5 lg:px-10 space-y-5 sm:space-y-10 py-5">
                    <div className="parallax from-left">
                        <h3 className="text-primary text-xs sm:text-3xl font-light uppercase">A campanha</h3>
                    </div>
                    <div className="w-3/4 sm:w-auto mx-auto">
                        <img src={logoAbrace} alt="Abrace a Enfermagem" />
                    </div>
                    <div className="text-xs sm:text-xl text-gray-1 space-y-5">
                        <p>
                            Abraçar a Enfermagem é valorizar e reconhecer os serviços prestados à população, garantindo cuidados em saúde para todos. Em meio ao caos e sem muitos recursos, profissionais do Brasil inteiro salvaram vidas e ajudam a liderar a linha de frente de combate à COVID-19.
                        </p>
                        <p><strong className="text-primary">Agora, chegou a hora de você retribuir essa dedicação.</strong></p>
                    </div>
                </div>
            </Container>
        </section>
    )
}