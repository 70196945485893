import gsap from "gsap";

// export function gsapAnimateFrom(elem: any, direction?: any) {
//     direction = direction | 1;

//     let x = 0,
//         y = direction * 100;
//     if (elem.classList.contains("gs_reveal_fromLeft")) {
//         x = -100;
//         y = 0;
//     } else if (elem.classList.contains("gs_reveal_fromRight")) {
//         x = 100;
//         y = 0;
//     }
//     gsap.fromTo(elem, { x: x, y: y, autoAlpha: 0 }, {
//         duration: 1.25,
//         x: 0,
//         y: 0,
//         autoAlpha: 1,
//         ease: "expo",
//         overwrite: "auto"
//     });
// }

// export function gsapAnimateHide(elem: any) {
//     gsap.set(elem, { autoAlpha: 0 });
// }

export function funcParallax(idElem: any, scrollTrigger$?: any) {
  const scrollTrigger = {
    ...{
      trigger: idElem?.current || "",
      start: "top bottom",
      end: "top top",
      scrub: true,
      // markers: true
    },
    ...scrollTrigger$,
  };

  if (idElem.current) {
    const tl = gsap.timeline({ scrollTrigger });

    gsap.utils
      .toArray(idElem?.current.querySelectorAll(".parallax"))
      .forEach((layer: any) => {
        const depth = layer.dataset.depth || 1;
        const opacity = !isNaN(parseInt(layer.dataset.opacity)) ? parseInt(layer.dataset.opacity) : 1;

        if (layer.classList.contains("from-left")) {
          const movement = -200 * depth;
          tl.fromTo(
            layer,
            { x: movement, opacity: opacity, ease: "expo" },
            { x: 0, opacity: 1, ease: "expo" },
            0
          );
        } else {
          const movement = (layer.offsetHeight || 200) * depth;
          tl.fromTo(layer, { y: movement }, { y: 0 }, 0);
        }
      });
  }
}

export function randomArray(arr: Array<any>) {
  return arr.sort(function () { return 0.5 - Math.random() });
}
