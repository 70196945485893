import React, { FC } from 'react';
import { Link } from 'react-router-dom';

interface ButtonProps {
    to?: any;
    className?: string;
    targetBlank?: boolean;
    type?: "button" | "submit" | "reset";
    disabled?: boolean;
    onClick?: () => void;
}

export const Button: FC<ButtonProps> = ({ to, className, type, disabled, onClick, children, targetBlank }) => {
    const classes = `inline-block rounded text-sm uppercase font-bold px-3 py-2 ${className || ''}`
    if (to) {
        if (targetBlank) {
            return <a onClick={onClick} className={classes} href={to} target={"_blank"} rel="noreferrer">{children}</a>
        } else {
            return <Link onClick={onClick} to={to} className={classes}>{children}</Link>
        }
    }

    return <button disabled={disabled} type={type || 'button'} onClick={onClick} className={classes}>{children}</button>
}
