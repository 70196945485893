import React, { useEffect, useRef } from 'react';
import { Button, Container } from '../../../components';
import { ROUTES } from '../../../constants';
import { funcParallax } from '../../../utils';

export default function MemorialSection() {
    const idElem: any = useRef(null);

    useEffect(() => {
        setTimeout(() => {
            funcParallax(idElem, {
                start: "top center",
            })
        }, 1000);
    }, []);
    return (
        <section id="memorial" ref={idElem} className="flex bg-white relative">
            <div className="bg-left hidden sm:block" />
            <Container className="relative flex-1">
                <div className="flex flex-col sm:flex-row h-full sm:items-center -mx-3">
                    <div className="flex flex-1 sm:flex-auto sm:w-1/2 justify-center items-center bg-mobile py-5">
                        <h2 className="uppercase text-center sm:text-left text-white">
                            <strong>
                                <span data-opacity={0} data-depth={2 * 1} className="parallax from-left block">uma</span>
                                <span data-opacity={0} data-depth={2 * 1.5} className="parallax from-left block">homenagem</span>
                                <span data-opacity={0} data-depth={2 * 2} className="parallax from-left block">DO SISTEMA</span>
                                <span data-opacity={0} data-depth={2 * 2.5} className="parallax from-left block">Cofen</span>
                                <span data-opacity={0} data-depth={2 * 3} className="parallax from-left block">Conselhos</span>
                                <span data-opacity={0} data-depth={2 * 3.5} className="parallax from-left block">Regionais</span>
                            </strong>
                        </h2>
                    </div>
                    <div className="flex-1 sm:flex-auto sm:w-1/2 px-8 sm:px-10 lg:pl-24 lg:pr-10 mt-10 sm:mt-0 space-y-5 sm:space-y-10 py-5">
                        <div data-opacity={0} className="text-center sm:text-left">
                            <h3 className="parallax from-left text-primary text-xs sm:text-3xl font-light uppercase">O memorial</h3>
                        </div>
                        <div className="text-xs sm:text-xl text-gray-1">
                            <p>O memorial é uma singela homenagem do Sistema Cofen/Conselhos Regionais aos profissionais de Enfermagem que perderam suas vidas durante o enfrentamento da pandemia. Homens e mulheres que compartilhavam da mesma paixão pela profissão e se dedicaram integralmente ao combate à COVID-19, colocando sempre em primeiro lugar a saúde da população.</p>
                        </div>
                        <Button to={ROUTES.MEMORIAL()} className="w-full sm:w-auto bg-secondary text-white text-xs sm:px-10 lg:px-20 py-3 text-center">VISITAR SITE MEMORIAL</Button>
                    </div>
                </div>
            </Container>
        </section>
    )
}