import React from 'react';
import './style.scss';
import AcaoSection from './Secoes/Acao';
import CampanhaSection from './Secoes/Campanha';
import DocumentarioSection from './Secoes/Documentario';
import InicialSection from './Secoes/Inicial';
import LivroSection from './Secoes/Livro';
import MemorialSection from './Secoes/Memorial';

export default function HomePage() {
    return (
        <>
            <InicialSection />
            <CampanhaSection />
            <DocumentarioSection />
            <AcaoSection />
            <LivroSection />
            <MemorialSection />
        </>
    )
}