import React, { useEffect } from 'react';
import { ProvideLayout } from './contexts';
// import { ProvideLayout } from './contexts';
import Routes from './routes';
// import gsap from 'gsap';
// import ScrollTrigger from "gsap/ScrollTrigger";
// import { gsapAnimateFrom, gsapAnimateHide } from './utils';



const App = () => {
  return (
    <ProvideLayout>
      <Routes />
    </ProvideLayout>
  )
}

export default App;