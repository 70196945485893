import React from "react";
import {
  Switch,
  Route
} from "react-router-dom";
import { ROUTES } from "../constants";
import HomePage from '../pages/Home';
import MemorialPage from "../pages/memorial";

export default function RoutesLayout() {
  return (
    <Switch>
      <Route path={ROUTES.MEMORIAL()} component={MemorialPage} />
      <Route path={ROUTES.HOME()} component={HomePage} />
    </Switch>
  );
}