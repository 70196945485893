import React from 'react';
import SwiperCore, { SwiperOptions, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Button, Container } from '../../../components';

const title = 'O Livro';
const options: SwiperOptions = {
    spaceBetween: 20,
    slidesPerView: 'auto',
    lazy: true,
    freeMode: true,
    loop: true,
}
SwiperCore.use([Navigation]);
const photos = [
    '1.jpg',
    '2.jpg',
    '3.jpg',
    '4.jpg',
    '5.jpg',
    '6.jpg',
    '7.jpg',
    '8.jpg',
    '9.jpg',
    '10.jpg',
    '11.jpg'
]
export default function LivroSection() {
    return (
        <section id="livro" className="bg-tertiary text-white pt-20 flex flex-col">
            <div className="parallax text-center from-left sm:hidden mb-5">
                <h3 className="text-xs font-light uppercase">{title}</h3>
            </div>
            <div className="flex-1">
                <Swiper
                    navigation
                    className="bg-blue-extralight z-40 text-white"
                    {...options}
                >
                    {
                        photos.map((photo, index) => {
                            const t = require(`../../../assets/imgs/livro/${photo}`);
                            return (
                                <SwiperSlide key={index} style={{ width: 'auto', maxWidth: '80%' }}>
                                    <div className="h-full bg-primary">
                                        <img className="object-cover h-full" src={t.default} alt="" />
                                    </div>
                                </SwiperSlide>
                            )
                        })
                    }
                </Swiper>
            </div>
            <Container className="flex flex-col sm:flex-row  justify-between items-center py-5 sm:py-10 ">
                <div className="parallax from-left hidden sm:inline-block whitespace-no-wrap">
                    <h3 className="text-3xl font-light uppercase">{title}</h3>
                </div>
                <div className="w-full sm:w-6/12 my-10 sm:my-0">
                    <p>O livro <strong>Na Linha de Frente</strong> reproduz imagens registradas do cotidiano da Enfermagem durante a pandemia. A obra destaca os principais desafios enfrentados no ano atípico de 2020 e exalta os serviços prestados pela categoria em prol da saúde da população.</p>
                </div>
                <Button to="/livro/cofen_abracenfermagem_livro.pdf" targetBlank className="bg-secondary text-white text-xs py-3 px-10 mb-5 sm:mb-0 uppercase"><span className="text-xs sm:text-base">Baixe o livro em PDF</span></Button>
            </Container>
        </section >

    )
}