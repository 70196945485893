import React, { FC } from 'react';

import './style.scss';

// interface CardMemorialProps {
//     isVertical: boolean;
//     title?: string;
//     descricao?: string;
//     img?: string;
// }

export const CardMemorial: FC<any> = (props) => {
    return (
        <div className={`relative ${props?.tipo === 'retrato' ? 'span-2' : ''} `}>
            {props?.foto?.sizes && (
                <img src={props?.foto?.sizes[props?.tipo]} loading="lazy" />
            )}
            <div className={`absolute top-0 bottom-0 left-0 right-0 bg-primary bg-opacity-75 transition duration-500 ease-in-out opacity-0 hover:opacity-100  overflow-auto`}>
                <div className="flex flex-col justify-center items-center text-white p-6 text-center space-y-3 min-h-full">
                    <div>
                        <h3 className="text-xl leading-tight"><strong>{props?.title}</strong></h3>
                        <p>{props?.cargo}{' '}{props.idade ? `| ${props.idade}` : ''}</p>
                    </div>
                    <p>{props?.descricao}</p>
                </div>
            </div>
        </div>
    )
}