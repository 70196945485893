import React, { useEffect, useState } from 'react';
import { useLayout } from '../../contexts';
import bg from '../../assets/imgs/bg-memorial.png';
import arrowDown from '../../assets/imgs/arrow-down.svg';
import imgLoading from '../../assets/imgs/loading.svg';

import './style.scss';
import { CardMemorial } from './card';
import Services from '../../services';

export default function MemorialPage() {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<any>([]);
    const layoutContext = useLayout();
    const { setIsMemorialPage } = layoutContext;
    useEffect(() => {
        window.scroll({
            top: 0,
            left: 0,
        });

        getData();

        setIsMemorialPage(true);
        return () => {
            setIsMemorialPage(false);
        }
    }, []);

    async function getData() {
        setLoading(true);
        try {
            const resp = await Services.getMemorial();
            setData(resp?.data);
        } catch (error) {
            alert('Algo deu errado. Tente novamente.')
        } finally {
            setLoading(false);
        }
    }
    return (
        <div className="memorial-page min-h-screen bg-black">
            <div className="header relative flex flex-col justify-center uppercase font-bold items-center text-white text-center pt-10 sm:pt-32">
                <img className="absolute h-full w-full top-0 object-cover" src={bg} alt="" />
                <div className="absolute h-full w-full top-0 bg-black bg-opacity-50" />
                <div className="relative py-6">
                    <div className="px-10 text-4xl xl:text-6xl">
                        <h2>
                            <span className="block">uma homenagem </span>
                            <span className="block">Sistema </span>
                            <span><span className="inline-block">Cofen/</span>Conselhos </span>
                            <span className="block">Regionais</span>
                            <span className="block">de Enfermagem</span>
                        </h2>
                    </div>
                </div>
                <img className="absolute bottom-0 mb-5" src={arrowDown} />
            </div>
            {
                loading
                    ? (
                        <div className="w-screen h-screen flex justify-center items-start pt-10">
                            <img src={imgLoading} width="100" alt="" />
                        </div>
                    )
                    : (
                        <div id="mosaico">
                            {data.map((item: any, index: number) => {
                                return (
                                    <CardMemorial
                                        key={index}
                                        {...item}
                                    />
                                )
                            })}
                        </div>
                    )
            }
        </div>
    )
}