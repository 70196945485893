import React, { FC, useEffect, useRef, useState } from 'react';
import { Container } from '../../../components';
import foto from '../../../assets/imgs/banner-influencers.png';
import { funcParallax, randomArray } from '../../../utils';
import Services from '../../../services';
import imgLoading from '../../../assets/imgs/loading.svg';

const ImgsAcao = ({ images }: any) => {
    const [imagesRand, setImagesRand] = useState<Array<any>>([]);

    useEffect(() => {
        handleRandImages();
        const interval = setInterval(() => {
            handleRandImages();
        }, 3000);

        return () => {
            clearInterval(interval);
        }
    }, []);

    function handleRandImages() {
        setImagesRand(randomArray([...images]));
    }

    if (!imagesRand.length) return null;

    return (
        <div className="h-full w-full relative">
            <div className="acao-img--1">
                <span className="name">{imagesRand[0]?.title}</span>
                <span className="rede">{imagesRand[0]?.rede_social}</span>
                <img src={imagesRand[0]?.foto?.sizes?.influencer} alt="" />
            </div>
            <div className="acao-img--2">
                <span className="name">{imagesRand[1]?.title}</span>
                <span className="rede">{imagesRand[1]?.rede_social}</span>
                <img src={imagesRand[1]?.foto?.sizes?.influencer} alt="" />
            </div>
            <div className="acao-img--3">
                <span className="name">{imagesRand[2]?.title}</span>
                <span className="rede">{imagesRand[2]?.rede_social}</span>
                <img src={imagesRand[2]?.foto?.sizes?.influencer} alt="" />
            </div>
            <img src={foto} alt="" />
        </div>
    )
}

export default function AcaoSection() {
    const idElem: any = useRef(null);

    const [data, setData] = useState<Array<any>>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            funcParallax(idElem)
        }, 1000);

        getData();
    }, []);

    async function getData() {
        setLoading(true);
        try {
            const resp = await Services.getInfluencers();
            setData(resp?.data);

        } catch (error) {
            alert('Algo deu errado. Tente novamente.')
        } finally {
            setLoading(false);
        }
    }
    return (
        <section id="acao" ref={idElem} className="bg-gray-2 flex flex-col py-5">
            <Container className="flex flex-col flex-1 pb-16 text-center sm:text-left relative">
                <div className="px-3 flex-1 flex items-center sm:px-0">
                    <div className="w-full sm:w-3/5">
                        <div className="w-full lg:w-2/3 space-y-10">
                            <h3 className="parallax from-left text-primary text-xs sm:text-xl lg:text-3xl font-light uppercase">#AbraceAEnfermagem</h3>
                            <div className="sm:hidden">
                                {(!loading && data?.length > 0) && <ImgsAcao images={data} />}
                            </div>
                            <div className="space-y-5">
                                <p><strong>Faça parte!</strong></p>
                                <p>
                                    Esse espaço é dedicado para homenagear e apoiar profissionais de Enfermagem do Brasil inteiro pela coragem e dedicação demonstradas durante a pandemia, mesmo com recursos escassos, longas jornadas de trabalho e salários incompatíveis com a realidade. O gesto criado para a campanha representa o reconhecimento da população pelos serviços prestados no enfrentamento à COVID-19 e reforça a importância de abraçar a categoria, que vem sofrendo nos últimos anos com a falta de valorização profissional.
                                </p>
                                <p>
                                    Para participar da ação é fácil: tire uma foto replicando o gesto do abraço {'>'} poste no feed do seu Instagram {'>'} coloque na legenda a hashtag <strong className="text-primary">#ABRACEAENFERMAGEM</strong>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="hidden sm:block sm:w-2/5">
                        {(!loading && data?.length > 0) && <ImgsAcao images={data} />}
                    </div>
                </div>
            </Container>
            <div className="bg-white">
                {
                    loading
                        ? (
                            <div className="w-screen flex justify-center items-start py-10">
                                <img src={imgLoading} width="100" alt="" />
                            </div>
                        )
                        : (

                            <Container className="transform -translate-y-3 sm:-translate-y-8">
                                <div className="flex justify-around space-x-3 -mx-3 sm:mx-0">
                                    {
                                        data?.length > 0 && randomArray([...data]).slice(0, 7).map((img, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className={index >= 4 ? 'hidden sm:inline-block' : ''}
                                                >
                                                    <img src={img?.foto?.sizes?.influencer} alt="" />
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <div className="uppercase text-center mt-5">
                                    <p><strong>Manifeste seu apoio a causa</strong></p>
                                </div>
                            </Container>
                        )
                }
            </div>
        </section>
    )
}