import axios from 'axios';
const Services = {
    getInfluencers() {
        return axios.get(`${process?.env?.REACT_APP_API}/wp-json/api/influencers`);
    },
    getMemorial() {
        return axios.get(`${process?.env?.REACT_APP_API}/wp-json/api/memorial`);
    }
}

export default Services;