import React, { useState } from 'react';
import Rodal from 'rodal';
import { Container } from '../../../components';
import play from '../../../assets/imgs/play.svg';
import ReactPlayer from 'react-player';

export default function InicialSection() {
    const [modalVisible, setModalVisible] = useState(false);
    function openVideo(e: any) {
        e.preventDefault();
        setModalVisible(true);
    }
    return (
        <>
            <section id="home" className="bg-white">
                <header className="bg-gray-2 py-6 sm:py-16 sm:pb-12"><div className="h-10"></div></header>
                <Container className="sm:-mt-12">
                    <a href="#this" className="-mx-3 cursor-pointer" onClick={openVideo}>
                        <div className="video text-white">
                            <div className="bg-multiply">
                                <video playsInline autoPlay muted loop>
                                    <source src="/video-home.mp4" type="video/mp4" />
                                </video>
                                <div className="bg" />
                            </div>

                            <div className="relative h-full flex flex-col justify-center items-center text-center">
                                <img className="w-48 my-5 sm:my-10" src={play} alt="Play" />
                                <h2>Conheça e <span className="block sm:inline">abrace a </span><span className="block">Enfermagem</span></h2>
                                {/* <h4 className="uppercase text-base font-light mt-5">Assista ao trailer</h4> */}
                            </div>
                        </div>
                    </a>
                </Container>
            </section>
            <Rodal visible={modalVisible} width={100} height={100} measure="%" onClose={() => setModalVisible(false)} customStyles={{background: 'black'}}>
                <div className="absolute top-1/2 left-1/2 text-center">...</div>
                <div className="relative h-full">
                    {modalVisible && <ReactPlayer width="100%" height="100%" controls={true} playing={modalVisible} url='https://youtu.be/2DbnVbAFPXQ' />}
                </div>
            </Rodal>
        </>
    )
}