import React, { useEffect, useState } from 'react';
import { Container } from '../../components';
import { useLayout } from '../../contexts';
import RoutesLayout from '../../routes/layout';
import SplashPage from '../Splash';
import logo from '../../assets/imgs/logo-cofen_abrace@2x.png';
import logoNaLinha from '../../assets/imgs/logo-na-linha-de-frente.png';
import { Link, useLocation } from 'react-router-dom';
import { ROUTES } from '../../constants';

export default function Layout() {
    let location = useLocation();
    const layoutContext = useLayout();
    const [menuOpen, setMenuOpen] = useState(false);
    const { fullLoading, headerBg, setHeaderBg, isMemorialPage } = layoutContext;
    const { hash } = location;

    useEffect(() => {
        if (location?.hash) {
            const selector = document?.querySelector(location.hash);

            // setTimeout(() => {
            selector?.scrollIntoView({
                behavior: 'smooth'
            });
            // }, 500)
        }
    }, [hash]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, [headerBg]);

    function handleScroll() {
        const isHeaderBg = window.scrollY > 60 ? true : false;
        if (headerBg !== isHeaderBg) {
            setHeaderBg(isHeaderBg);
        }
    }

    function handleStart() {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }

    function handleClick() {
        setMenuOpen(false);
    }

    if (fullLoading) {
        return <SplashPage />;
    }
    const bgClass = isMemorialPage ? 'bg-black' : `${headerBg ? 'bg-gray-2 bg-opacity-50' : ''}`;
    return (
        <div className="flex-1 flex flex-col overflow-x-hidden">
            <main className="flex-1 relative overflow-hidden">
                <div className={`fixed w-full z-50 transition-all duration-300 ${bgClass}`}>
                    <Container className="py-6 relative flex items-center justify-between">
                        {!isMemorialPage && <Link className="inline-block" to={ROUTES.HOME()} onClick={handleStart}><img className="logo" src={logo} alt="" /></Link>}
                        {isMemorialPage && <img className="logo-na-linha mx-auto" src={logoNaLinha} alt="" />}

                        <div className={`menu flex items-center ${menuOpen ? 'open' : ''}`}>
                            <button
                                type="button"
                                className={`btn-menu flex flex-col items-end w-10 bg-opacity-100 space-y-2 focus:outline-none ${isMemorialPage ? 'btn-memorial' : '' }`}
                                onClick={() => setMenuOpen(!menuOpen)}
                            >
                                <span className="w-full h-1"></span>
                                <span className="w-full h-1"></span>
                                <span className="w-3/4 h-1"></span>
                            </button>
                            <div className="items-menu min-h-screen absolute top-0 right-0 text-white px-10 text-right">
                                <ul className="flex-1 flex flex-col justify-around">
                                    <li>
                                        <Link to={{
                                            pathname: ROUTES.HOME(),
                                            hash: "#campanha",
                                        }}
                                            onClick={handleClick}
                                        >Campanha</Link>
                                    </li>
                                    <li>
                                        <Link to={{
                                            pathname: ROUTES.HOME(),
                                            hash: "#documentario",
                                        }}
                                            onClick={handleClick}
                                        >Documentário</Link>
                                    </li>
                                    <li>
                                        <Link to={{
                                            pathname: ROUTES.HOME(),
                                            hash: "#acao",
                                        }}
                                            onClick={handleClick}
                                        >Ação</Link>
                                    </li>
                                    <li>
                                        <Link to={{
                                            pathname: ROUTES.HOME(),
                                            hash: "#livro",
                                        }}
                                            onClick={handleClick}
                                        >Livro</Link>
                                    </li>
                                    <li>
                                        <Link to={{
                                            pathname: ROUTES.HOME(),
                                            hash: "#memorial",
                                        }}
                                            onClick={handleClick}
                                        >Memorial</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </Container>
                </div>
                <RoutesLayout />
            </main>
        </div>
    )
}