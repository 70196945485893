import React, { useEffect, useRef, useState } from 'react';
import Rodal from 'rodal';
import { Button, Container } from '../../../components';
import play from '../../../assets/imgs/play.svg';
import floatImg from '../../../assets/imgs/img-documentario.png';
import { funcParallax } from '../../../utils';
import ReactPlayer from 'react-player';

export default function DocumentarioSection() {
    const idElem: any = useRef(null);
    const [modalVisible, setModalVisible] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            funcParallax(idElem)
        }, 1000);
    }, []);
    
    function openVideo() {
        setModalVisible(true);
    }


    return (
        <>
            <section id="documentario" ref={idElem} className="bg-white relative flex px-8 pt-5 pb-10">
                <Container className="flex flex-wrap flex-1 items-center text-center sm:text-left">
                    <div className="w-full sm:w-3/5 sm:px-5 lg:px-10">
                        <div className="w-full sm:w-2/3">
                            <div className="space-y-5 sm:space-y-10 py-5">
                                <h3 className="parallax from-left text-primary text-xs sm:text-3xl font-light uppercase">O documentário</h3>
                                <div className="text-xs sm:text-xl text-gray-1">
                                    <p>
                                        <strong>Na Linha de Frente</strong>
                                        <br />
                                        <br />
                                    A pandemia causada pela COVID-19 impressionou o mundo e escancarou as deficiências estruturais da saúde no Brasil. O documentário expõe os problemas históricos da categoria, como as longas jornadas de trabalho e os baixos salários ofertados, além de revelar os porquês da lentidão para aprovação dessas reivindicações. Profissionais da Enfermagem, que ajudaram a liderar a linha de frente de combate à doença, sentiram os impactos de perto e confrontaram um dos maiores desafios da história. A falta de recursos humanos, equipamentos e leitos, além do medo de infectar seus familiares, são alguns dos obstáculos que foram enfrentados e que nunca mais serão esquecidos.
                                </p>
                                </div>
                            </div>
                        </div>
                        <div className="bg-video relative sm:absolute flex flex-col sm:w-2/5 sm:right-0 sm:top-0 h-full mt-0 text-white text-center justify-center items-center py-12 sm:py-5 rounded-lg sm:rounded-none">
                            <div className="bg-multiply">
                                <video playsInline autoPlay muted loop>
                                    <source src="/video-campanha.mp4" type="video/mp4" />
                                </video>
                                <div className="bg" />
                            </div>
                            <div data-depth={2} className="parallax hidden lg:block w-2/4 absolute left-0 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
                                <img src={floatImg} alt="img" />
                            </div>
                            <button onClick={openVideo} className="relative">
                                <img className="w-48 my-5 sm:my-10" src={play} alt="Play" />
                                <h4 className="uppercase text-base font-light mt-12 sm:mt-5">Assista ao documentário</h4>
                            </button>
                        </div>
                        <div className="w-full sm:w-2/3">
                            <Button className="w-full bg-secondary text-white text-xs py-3 mt-10 focus:outline-none">
                                <span className="text-xs sm:text-base uppercase">
                                    estréia 09 de dezembro
                                {/* ASSISTA AO DOCUMENTÁRIO COMPLETO */}
                                </span>
                            </Button>
                        </div>
                    </div>
                </Container>
            </section>
            <Rodal visible={modalVisible} width={100} height={100} measure="%" onClose={() => setModalVisible(false)} customStyles={{ background: 'black' }}>
                <div className="absolute top-1/2 left-1/2 text-center">...</div>
                <div className="relative h-full">
                    {modalVisible && <ReactPlayer width="100%" height="100%" controls={true} playing={modalVisible} url='https://youtu.be/8RzqWECyPsg' />}
                </div>
            </Rodal>
        </>
    )
}